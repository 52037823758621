import { DeviceType } from "../../types";
import filterDevices from "../filterDevices";
import getMobileOperatingSystem from "../getMobileOperatingSystem";

export function findUltraWideCamera(
    devices: DeviceType[]
): DeviceType | undefined {
    return devices.find(
        (device) =>
            device.kind === "videoinput" &&
            device.label.toLowerCase().includes("ultra")
    );
}

const getVideoDeviceID = ({
    devices,
    side,
    desktopTest,
    onError,
    onSuccess,
}: {
    devices: DeviceType[];
    side: "front" | "back";
    desktopTest: boolean;
    onError: () => void;
    onSuccess: (id: string) => void;
}) => {
    if (desktopTest || !window.mobileAndTabletCheck()) {
        onSuccess(devices[0].deviceId);
    } else {
        const filteredDevices = filterDevices(devices, side);
        if (filteredDevices.length) {
            const isAndroid = getMobileOperatingSystem() === "Android";
            if (isAndroid) {
                const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
                // For Android, the appropriate camera can be found at the end of the video devices table except for Firefox, which is in first place for back side.
                // Assumption: The one with label "camera2 0, facing back" for rear camera and "camera2 1, facing front" for front camera.
                if (isFirefox && side === "back") {
                    onSuccess(filteredDevices[0].deviceId);
                } else {
                    onSuccess(filteredDevices[filteredDevices.length - 1].deviceId);
                }
            } else {
                // For IOS, the appropriate camera is the one with the shortest label.
                // const device = filteredDevices.reduce((a, b) =>
                //     a.label.length <= b.label.length ? a : b
                // );
                // onSuccess(device.deviceId);
                onSuccess("");
            }
        } else {
            onError();
        }
    }
};

export default getVideoDeviceID;
