import { useEffect, useState } from "react";
import { useData } from "../../../../context/data";
import {
  addLivenessVideo,
  updateStatus,
} from "../../../../context/data/action";
import {
  useRecording,
  useLivenessDetection,
  useTimeout,
} from "../../../../hooks";
import {
  FaceAICodes,
  ChallengeComponentProps,
  ChallengeOrdersType,
  Pages,
} from "../../../../types";
import { getSupportedMimeType } from "../../../../utils";
import LeftArrowsAnimation from "../../../../icons/Animations/ArrowsLeft";
import { useMultilangue } from "../../../../context/multilangue";
import { Content, Footer, Header } from "../Structure";
import { HeadLeft } from "../../../../icons";
import Webcam from "react-webcam";
import { supportFormatWebp } from "../../../../utils";
import { Container } from "../../../../components";
import { handleHeaderTitle } from "../Structure/Header";
import FailCard from "../Structure/FailCard";
import { useRouter } from "../../../../context/router";

const Left: React.FC<ChallengeComponentProps> = ({
  next,
  index,
  setStep,
  deviceId,
}): JSX.Element => {
  const { dispatch } = useData();
  const { goto } = useRouter();
  const {
    t: {
      words: {
        scan_liveness: { left_title, errors },
      },
    },
  } = useMultilangue();
  const {
    webcamRef,
    hasTimeout,
    aiTimer,
    setRecording,
    callbackEndTimeout,
    webcamStarted,
    timeoutTimer,
    nextPageTimer,
    handleStopCapture,
    recordingTimer,
    done,
    recordedChunks,
    setNextPageTimer,
    stopTimeoutTimer,
    setWebcamStarted,
    startTimeoutTimer,
    startAiTimer,
    stopAiTimer,
    setHasTimeout,
  } = useRecording({ step: "liveness", delay: 100 });
  const [code, setCode] = useState<FaceAICodes>(0);

  const videoConstraints: MediaTrackConstraints = {
    ...(deviceId ? { deviceId } : { facingMode: "user" }),
    height: 720,
    width: 1280,
    focusMode: { exact: "continuous" },
    frameRate: 30,
  };

  // Challenge correctly detected
  const detected = useLivenessDetection({
    webcamRef,
    hasTimeout,
    delay: aiTimer!,
    challenge: ChallengeOrdersType.LEFT,
    setCode,
    setStep,
  });

  // Stop the timer when user challenge is detected and start recording the video
  useEffect(() => {
    if (detected && !hasTimeout) {
      setRecording(true);
      stopAiTimer();
      stopTimeoutTimer();
    }
  }, [detected, hasTimeout]);

  // reset the timer until the challenge is successful
  useTimeout(callbackEndTimeout, webcamStarted ? timeoutTimer : null);
  // timer before going to the next page
  useTimeout(next, nextPageTimer);
  // timer which represents the recording time of the video
  useTimeout(handleStopCapture, recordingTimer);

  // Waiting for the video to be recorded to create a Blob with the recorded chunks and set it to the store
  useEffect(() => {
    if (done) {
      if (recordedChunks.length) {
        const mimeType = getSupportedMimeType();
        const blob = new Blob(recordedChunks, {
          type: mimeType || recordedChunks[0].type,
        });
        dispatch(addLivenessVideo({ file: blob, index }));
        setNextPageTimer(2000);
      } else {
        goto({
          page: Pages.ERROR_PAGE,
          props: {
            message: "VideoRecordingError",
            code: 702,
            description: ["Something went wrong while recording the video."],
          },
        });
      }
    }
  }, [done, recordedChunks]);

  return (
    <Container className={"h-full"}>
      <Webcam
        className="absolute inset-0 object-cover w-screen h-screen"
        ref={webcamRef}
        screenshotFormat={supportFormatWebp() ? "image/webp" : "image/jpeg"}
        onUserMedia={() => setWebcamStarted(true)}
        mirrored={true}
        videoConstraints={videoConstraints}
        forceScreenshotSourceSize={true}
        screenshotQuality={1}
        onUserMediaError={(error) => {
          dispatch(updateStatus("error"));
          goto({
            page: Pages.ERROR_PAGE,
            props: {
              message: "CameraSettingError",
              code: 701,
              description: [
                "Something went wrong during the setting of camera or record buffer.",
                ...(typeof error !== "string" ? [error?.message] : [error]),
              ],
            },
          });
        }}
      />
      <Header
        title={handleHeaderTitle(left_title, errors, code)}
        icon={<HeadLeft />}
        display={Boolean(!done && !hasTimeout)}
        code={code}
      />
      {hasTimeout ? (
        <FailCard
          retryFn={() => {
            startTimeoutTimer();
            startAiTimer();
            setHasTimeout(false);
            setCode(0);
          }}
          cancelFn={() => setStep(0)}
        />
      ) : (
        <Content done={done} frame={"left"} />
      )}
      <Footer
        done={done}
        hasTimeout={hasTimeout}
        animation={LeftArrowsAnimation()}
        width={100}
      />
    </Container>
  );
};

export default Left;
