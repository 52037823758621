import Webcam from "react-webcam";
import { Container } from "../../../components";
import { useData } from "../../../context/data";
import { DocAICodes, CaptureDocumentType, Pages } from "../../../types";
import { useRecording, useDocumentDetection, useTimeout } from "../../../hooks";
import { getSupportedMimeType, supportFormatWebp } from "../../../utils";
import { useEffect, useState } from "react";
import {
  addBackDocument,
  addFrontDocument,
  updateStatus,
} from "../../../context/data/action";
import DocCaptureHeader from "./DocCaptureHeader";
import DocCaptureCard from "./DocCaptureCard";
import Signature from "./Signature";
import { useRouter } from "../../../context/router";

const CaptureDocument: React.FC<CaptureDocumentType> = ({
  deviceId,
  next,
  side,
  setStep,
}) => {
  const {
    dispatch,
    data: {
      data: { documentType, documentCountry },
    },
  } = useData();
  const { goto } = useRouter();

  const [docCode, setDocCode] = useState<DocAICodes>(0);
  const {
    webcamRef,
    hasTimeout,
    aiTimer,
    setRecording,
    callbackEndTimeout,
    webcamStarted,
    timeoutTimer,
    nextPageTimer,
    handleStopCapture,
    recordingTimer,
    done,
    recordedChunks,
    setNextPageTimer,
    recording,
    startTimeoutTimer,
    stopTimeoutTimer,
    startAiTimer,
    stopAiTimer,
    setHasTimeout,
    setWebcamStarted,
  } = useRecording({
    step: "doc",
    isPassport: documentType === "passport",
    delay: 500,
    delayedRecord: true,
    torch: true,
  });

  const [docPicture, setDocPicture] = useState<Blob | null>(null);

  const videoConstraints: MediaTrackConstraints = {
    ...(deviceId ? { deviceId } : { facingMode: "environment" }),
    height: 720,
    width: 1280,
    frameRate: 30,
  };

  // Conditions
  const isFront: boolean = side === "front";
  const isPassport: boolean = documentType === "passport";

  // Detect the document
  const detected = useDocumentDetection({
    webcamRef,
    documentConfig: {
      label: documentType!,
      isFront,
      country: documentCountry!,
    },
    hasTimeout,
    delay: aiTimer!,
    setDocCode,
    setDocPicture,
    setStep,
  });

  // Stop the timer when document is detected and start recording the video
  useEffect(() => {
    if (detected && !hasTimeout) {
      setRecording(true);
      stopAiTimer();
      stopTimeoutTimer();
    }
  }, [detected, hasTimeout]);

  // 8 sec to detect the document
  useTimeout(callbackEndTimeout, webcamStarted ? timeoutTimer : null);
  // timer before going to the next page
  useTimeout(next, nextPageTimer);
  // timer which represents the recording time of the video
  useTimeout(handleStopCapture, recordingTimer);

  // Waiting for the video to be recorded to create a Blob with the recorded chunks and set it to the store
  useEffect(() => {
    if (done) {
      if (recordedChunks.length) {
        const mimeType = getSupportedMimeType();
        const blob = new Blob(recordedChunks, {
          type: mimeType || recordedChunks[0].type,
        });
        dispatch(
          isFront
            ? addFrontDocument({
                frontDocumentFile: blob,
                pictureFrontDocument: docPicture,
              })
            : addBackDocument({
                backDocumentFile: blob,
                pictureBackDocument: docPicture,
              })
        );
        setNextPageTimer(2000);
      } else {
        goto({
          page: Pages.ERROR_PAGE,
          props: {
            message: "VideoRecordingError",
            code: 702,
            description: ["Something went wrong while recording the video."],
          },
        });
      }
    }
  }, [done, recordedChunks]);

  return (
    <Container className={"h-full bg-black"}>
      <Webcam
        className="absolute inset-0 object-contain w-full h-auto my-auto"
        ref={webcamRef}
        style={{
          transform: window.mobileAndTabletCheck() ? "scaleX(1)" : "scaleX(-1)",
        }}
        screenshotFormat={supportFormatWebp() ? "image/webp" : "image/jpeg"}
        onUserMedia={() => setWebcamStarted(true)}
        videoConstraints={videoConstraints}
        forceScreenshotSourceSize
        screenshotQuality={1}
        onUserMediaError={(error) => {
          dispatch(updateStatus("error"));
          goto({
            page: Pages.ERROR_PAGE,
            props: {
              message: "CameraSettingError",
              code: 701,
              description: [
                "Something went wrong during the setting of camera or record buffer.",
                ...(typeof error !== "string" ? [error?.message] : [error]),
              ],
            },
          });
        }}
      />
      <div className="flex flex-col w-full h-full justify-between items-center">
        <DocCaptureHeader
          display={Boolean(!detected && !hasTimeout)}
          docCode={docCode}
        />
        <DocCaptureCard
          done={done}
          hasTimeout={hasTimeout}
          isPassport={isPassport}
          recording={recording}
          restart={() => {
            startTimeoutTimer();
            startAiTimer();
            setHasTimeout(false);
            setDocCode(0);
          }}
        />
        <Signature />
      </div>
    </Container>
  );
};

export default CaptureDocument;
